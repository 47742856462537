import StandardGateway from './lib/standard_gateway';

// Override the default request timeout because embed reports request
// cookieless session tokens from Looker, which occasionally takes
// a while to respond.
const REQUEST_TIMEOUT = 60000;

export default class EmbedTokensReportGateway extends StandardGateway {
  constructor(backend) {
    super(backend, { addUrl: '/api/reporting/v1/orgs/:orgId/embedded/:type' });
  }

  add(params, data) {
    return super.add(params, data, { timeout: REQUEST_TIMEOUT });
  }
}
