import StandardGateway from 'scripts/adapters/gateways/lib/standard_gateway';

import { ConversationItemGateway, CustomerHistoryMergeGateway } from 'scripts/adapters/gateways/active_shared_gateways';
import ActiveCallGateway from 'scripts/adapters/gateways/active_call_gateway';
import AgentActivityGateway from 'scripts/adapters/gateways/agent_activity_gateway';
import AgentAssistanceConfigGateway from '../adapters/gateways/agent_assistance_config_gateway';
import AgentNotificationGateway from 'scripts/adapters/gateways/agent_notification_gateway';
import AgentPresenceGateway from 'scripts/adapters/gateways/agent_presence_gateway';
import AgentRoutingPreferencesGateway from 'scripts/adapters/gateways/agent_routing_preferences_gateway';
import AnswerSearchGateway from 'scripts/adapters/gateways/answer_search_gateway';
import AgentSearchResultGateway from 'scripts/adapters/gateways/agent_search_result_gateway';
import AgentStatusGateway from 'scripts/adapters/gateways/agent_status_gateway';
import AgentStatusesGateway from 'scripts/adapters/gateways/agent_statuses_gateway';
import AgentTaskReadGateway from 'scripts/adapters/gateways/agent_task_read_gateway';
import AgentUserGateway from 'scripts/adapters/gateways/agent_user_gateway';
import AnswerSuggestionGateway from 'scripts/adapters/gateways/answer_suggestion_gateway';
import ApiTokenGateway from 'scripts/adapters/gateways/api_token_gateway';
import AuthenticationGateway from 'scripts/adapters/gateways/authentication_gateway';
import ChatTypingHttpGateway from 'scripts/adapters/gateways/chat_typing_http_gateway';
import CompanyProfileGateway from 'scripts/adapters/gateways/company_profile_gateway';
import CompositionHttpGateway from 'scripts/adapters/gateways/composition_http_gateway';
import ConversationCountGateway from 'scripts/adapters/gateways/conversation_count_gateway';
import ConversationMessageGateway from 'scripts/adapters/gateways/conversation_message_gateway';
import ConversationWorkflowConfigGateway from 'scripts/adapters/gateways/conversation_workflow_config_gateway';
import createConfigurationGateway from 'scripts/adapters/gateways/create_configuration_http_gateway';
import createOutgoingCommunicationGateway from 'scripts/adapters/gateways/outgoing_communication_gateway';
import CreditCardGateway from 'scripts/adapters/gateways/credit_card_gateway';
import CurrentAgentProfileHttpGateway from 'scripts/adapters/gateways/current_agent_profile_http_gateway';
import CustomerAssigneeGateway from 'scripts/adapters/gateways/customer_assignee_gateway';
import CustomerAutoExtensionGateway from 'scripts/adapters/gateways/customer_auto_extension_gateway';
import CustomerExtensionGateway from 'scripts/adapters/gateways/customer_extension_http_gateway';
import CustomerHistoryGateway from 'scripts/adapters/gateways/customer_history_gateway';
import CustomerHistoryRedactionGateway from 'scripts/adapters/gateways/customer_history_redaction_gateway';
import CustomerMatchGateway from 'scripts/adapters/gateways/customer_match_gateway';
import CustomerTypingStateGateway from 'scripts/adapters/gateways/customer_typing_state_gateway';
import CustomReportingGateway from 'scripts/adapters/gateways/custom_reporting_gateway';
import DeviceStatusGateway from 'scripts/adapters/gateways/device_status_gateway';
import EmbeddedReportGateway from 'scripts/adapters/gateways/embedded_report_gateway';
import EmbedTokensGateway from 'scripts/adapters/gateways/embed_tokens_gateway';
import EmbedTokensReportGateway from 'scripts/adapters/gateways/embed_tokens_report_gateway';
import ExternalCustomerLookupActionsGateway from 'scripts/adapters/gateways/external_customer_lookup_actions_gateway';
import ExternalCustomerLookupGateway from 'scripts/adapters/gateways/external_customer_lookup_http_gateway';
import ExternalDataStoreGateway from 'scripts/adapters/gateways/external_data_store_gateway';
import FeatureSetGateway from 'scripts/adapters/gateways/feature_set_gateway';
import InboxHttpGateway from 'scripts/adapters/gateways/inbox_http_gateway';
import KbVariableGateway from 'scripts/adapters/gateways/kb_variable_http_gateway';
import LanguageGateway from 'scripts/adapters/gateways/language_gateway';
import LiveboardGateway from 'scripts/adapters/gateways/liveboard_gateway';
import NoopOnReconnectGateway from 'scripts/adapters/gateways/noop_on_reconnect_gateway';
import NoReplyNeededGateway from 'scripts/adapters/gateways/no_reply_needed_gateway';
import NotificationGateway from 'scripts/adapters/gateways/notification_gateway';
import OutgoingChatGateway from 'scripts/adapters/gateways/outgoing_chat_gateway';
import PhoneControlsHttpGateway from 'scripts/adapters/gateways/phone_controls_http_gateway';
import PhraseSuggestionsGateway from 'scripts/adapters/gateways/phrase_suggestions_gateway';
import PublicAnswerUploadGateway from 'scripts/adapters/gateways/public_answer_upload_gateway';
import ReportGateway from 'scripts/adapters/gateways/report_gateway';
import ReportingApiGateway from 'scripts/adapters/gateways/reporting_api_gateway';
import RoutableInboxCountsGateway from 'scripts/adapters/gateways/routable_inbox_counts_gateway';
import RoutingEventGateway from 'scripts/adapters/gateways/routing_event_gateway';
import RoutingGroupGateway from 'scripts/adapters/gateways/routing_group_http_gateway';
import RoutingQueueItemGateway from 'scripts/adapters/gateways/routing_queue_item_gateway';
import ScheduledReportGateway from 'scripts/adapters/gateways/scheduled_report_gateway';
import SearchGateway from 'scripts/adapters/gateways/search_gateway';
import ServerInfoGateway from 'scripts/adapters/gateways/server_info_gateway';
import SharedUploadGateway from 'scripts/adapters/gateways/shared_upload_gateway';
import SnippetCountGateway from 'scripts/adapters/gateways/snippet_count_gateway';
import SnippetGateway from 'scripts/adapters/gateways/snippet_gateway';
import SnippetSearchResultHttpGateway from 'scripts/adapters/gateways/snippet_search_result_http_gateway';
import StationConfigurationGateway from 'scripts/adapters/gateways/station_configuration_gateway';
import SystemConfigurationGateway from 'scripts/adapters/gateways/system_configuration_http_gateway';
import TeamGateway from 'scripts/adapters/gateways/team_gateway';
import TopicGateway from 'scripts/adapters/gateways/topic_http_gateway';
import TopicSuggestionsGateway from 'scripts/adapters/gateways/topic_suggestions_gateway';
import TweetGateway from 'scripts/adapters/gateways/tweet_gateway';
import TwilioPhoneGateway from 'scripts/adapters/gateways/twilio_phone_gateway';
import UploadGateway from 'scripts/adapters/gateways/upload_gateway';
import UserGateway from 'scripts/adapters/gateways/user_gateway';
import UserPasswordGateway from '../adapters/gateways/user_password_gateway';
import UsersUploadGateway from 'scripts/adapters/gateways/users_upload_gateway';
import VoiceRecordingGateway from 'scripts/adapters/gateways/voice_recording_gateway';
import WebhookGateway from 'scripts/adapters/gateways/webhook_gateway';

export default function({ backend, twilio, commSimulation, requestorId }) {
  return {
    account: new StandardGateway(backend, 'v1/orgs/:orgId/configuration/accounts'),
    activeCall: new ActiveCallGateway(backend, requestorId),
    agent: new StandardGateway(backend, 'v1/orgs/:orgId/agents/:agentId'),
    agentActivity: new AgentActivityGateway(backend, requestorId),
    agentAssignment: new StandardGateway(backend, 'v1/orgs/:orgId/agents/:agentId/assignments/:assignmentId'),
    agentAssistanceConfig: new AgentAssistanceConfigGateway(backend, requestorId),
    agentExperienceConfig: new StandardGateway(backend, 'v1/orgs/:orgId/configuration/agent-experience'),
    agentInbox: new StandardGateway(backend, 'v4/orgs/:orgId/agents/:agentId/inbox'),
    agentInboxV5: new StandardGateway(backend, 'v5/orgs/:orgId/agents/:agentId/inbox'),
    agentNextAssignment: new StandardGateway(backend, 'v1/orgs/:orgId/agents/:agentId/routing/next-assignment'),
    agentNotification: new AgentNotificationGateway(backend),
    agentPresence: new AgentPresenceGateway(backend),
    agentProfile: new StandardGateway(backend, 'v3/orgs/:orgId/agent-profiles/:agentId'),
    agentRead: new NoopOnReconnectGateway(backend, 'v1/orgs/:orgId/conversation/:conversationId/agent-reads/:agentId'),
    agentRoutingPreferences: new AgentRoutingPreferencesGateway(backend),
    agentSearchResult: new AgentSearchResultGateway(backend, requestorId),
    agentStatus: new AgentStatusGateway(backend),
    agentStatuses: new AgentStatusesGateway(backend),
    agentStatusReason: new StandardGateway(backend, 'v1/orgs/:orgId/agent-status-reasons'),
    agentTaskRead: new AgentTaskReadGateway(backend, requestorId),
    agentUser: new AgentUserGateway(backend, requestorId),
    aiConversationSummary: new NoopOnReconnectGateway(backend, {
      fetchUrl: '/api/v1/orgs/:orgId/conversations/:conversationId/summary',
      addUrl: '/api/v1/orgs/:orgId/conversations/:conversationId/summary/items',
    }),
    aiTextCompletion: new NoopOnReconnectGateway(backend, 'v1/orgs/:orgId/ai-text-completion'),
    aiSuggestedReply: new NoopOnReconnectGateway(backend, 'v1/orgs/:orgId/ai-suggested-replies'),
    answerSearch: new AnswerSearchGateway(backend, requestorId),
    answerSuggestion: new AnswerSuggestionGateway(backend, requestorId),
    apiToken: new ApiTokenGateway(backend, requestorId),
    audience: new StandardGateway(backend, 'v1/orgs/:orgId/audiences/:audienceId'),
    authentication: new AuthenticationGateway(backend),
    chatTyping: new ChatTypingHttpGateway(backend),
    commSimulation,
    communicationQueue: new StandardGateway(backend, 'v1/orgs/:orgId/configuration/communication-queues/:queueId'),
    companyProfile: new CompanyProfileGateway(backend, requestorId),
    complianceCustomer: new StandardGateway(backend, 'v1/orgs/:orgId/compliance/customers/:customerId'),
    composition: new CompositionHttpGateway(backend, requestorId),
    compositionSubscriber: new StandardGateway(backend, 'v1/orgs/:orgId/agents/:agentId/compositions/:compositionId'),
    compositionUpload: new UploadGateway(backend, requestorId),
    conversationCount: new ConversationCountGateway(backend, requestorId),
    conversationCustomAttributes: new NoopOnReconnectGateway(
      backend,
      'v1/orgs/:orgId/customer-history/:customerId/conversations/:conversationId/custom-attributes'
    ),
    conversationItem: new ConversationItemGateway(backend, requestorId),
    customAttributes: new StandardGateway(backend, 'v1/orgs/:orgId/custom-attributes/:customAttributeId'),
    customChannels: new StandardGateway(backend, 'v1/orgs/:orgId/custom-channels/:customChannelId'),
    customChannelsConfig: new StandardGateway(backend, 'v1/orgs/:orgId/configuration/customchannels'),
    customerHistoryRedaction: new CustomerHistoryRedactionGateway(backend),
    conversationMessage: new ConversationMessageGateway(backend, requestorId),
    conversationWorkflowConfig: new ConversationWorkflowConfigGateway(backend),
    creditCard: new CreditCardGateway(backend, requestorId),
    currentAgentProfile: new CurrentAgentProfileHttpGateway(backend),
    customerActiveSecureDataRequests: new StandardGateway(
      backend,
      'v1/orgs/:orgId/customer-history/:customerId/active-secure-data-requests'
    ),
    customerAssignee: new CustomerAssigneeGateway(backend, requestorId),
    customerAutoExtension: new CustomerAutoExtensionGateway(backend, requestorId),
    customerExtension: new CustomerExtensionGateway(backend, requestorId),
    customerExternalDataObjects: new ExternalDataStoreGateway(backend),
    customerHistory: new CustomerHistoryGateway(backend, requestorId),
    customerHistoryMerge: new CustomerHistoryMergeGateway(backend, requestorId),
    customerMatch: new CustomerMatchGateway(backend, requestorId),
    customerNote: new NoopOnReconnectGateway(backend, 'v1/orgs/:orgId/customer-notes/:customerId'),
    customerProfile: new NoopOnReconnectGateway(backend, 'v1/orgs/:orgId/customer-profiles/:customerId'),
    customerProfileMerge: new NoopOnReconnectGateway(backend, 'v1/orgs/:orgId/customer-profiles/:customerId/merges'),
    customerProfileDef: new StandardGateway(backend, 'v1/orgs/:orgId/configuration/customer-profile-def'),
    customerTransaction: new NoopOnReconnectGateway(backend, `v1/orgs/:orgId/customers/:customerId/transactions`),
    customerTypingState: new CustomerTypingStateGateway(backend, requestorId),
    customReporting: new CustomReportingGateway(backend),
    deviceStatus: new DeviceStatusGateway(navigator),
    emailSuggestions: new StandardGateway(backend, 'v1/orgs/:orgId/agents/:agentId/email-suggestions'),
    embeddedDataset: new StandardGateway(backend, 'reporting/v1/orgs/:orgId/embedded-datasets'),
    embeddedReport: new EmbeddedReportGateway(backend),
    embedTokens: new EmbedTokensGateway(backend),
    embedTokensReport: new EmbedTokensReportGateway(backend),
    endpoint: new StandardGateway(backend, 'v1/orgs/:orgId/configuration/endpoints/:endpointId'),
    externalCustomerLookup: new ExternalCustomerLookupGateway(backend, requestorId),
    externalCustomerLookupActions: new ExternalCustomerLookupActionsGateway(backend, requestorId),
    fbMessengerAccount: new StandardGateway(backend, 'v1/orgs/:orgId/configuration/public-accounts/facebook-messenger'),
    featureSet: new FeatureSetGateway(backend, requestorId),
    greetingSuggestions: new StandardGateway(backend, 'v1/orgs/:orgId/agents/:agentId/greeting-suggestions'),
    httplog: new StandardGateway(backend, {
      fetchAllUrl: 'api/v1/orgs/:orgId/httplogs',
    }),
    imitation: new StandardGateway(backend, 'v3/orgs/:orgId/users/:userId/desktop_tokens'),
    inboxHttp: new InboxHttpGateway(backend, requestorId),
    inboxCustomerItem: new StandardGateway(backend, 'v1/orgs/:orgId/inbox-item/customer/:id'),
    inboxTaskItem: new StandardGateway(backend, 'v1/orgs/:orgId/inbox-item/task/:id'),
    integrations: new StandardGateway(backend, 'v1/orgs/:orgId/integrations/:integrationId'),
    itemIds: new NoopOnReconnectGateway(backend, 'v1/orgs/:orgId/customer-history/:customerId/item-ids/:itemId'),
    ivr: new StandardGateway(backend, 'v1/orgs/:orgId/configuration/ivrs'),
    kbVariable: new KbVariableGateway(backend, requestorId),
    language: new LanguageGateway(backend, requestorId),
    liveboard: new LiveboardGateway(backend),
    messagingConfiguration: new StandardGateway(backend, 'v1/orgs/:orgId/configuration/messaging'),
    mobileNumberIdentificationConfig: new StandardGateway(backend, 'v1/orgs/:orgId/configuration/mobile-number-lookup'),
    noReplyNeeded: new NoReplyNeededGateway(backend, requestorId),
    notification: new NotificationGateway(window.localStorage),
    organizations: new StandardGateway(backend, 'v1/organizations/:orgId'),
    outgoingCall: createOutgoingCommunicationGateway('calls', backend, requestorId),
    outgoingChat: createOutgoingCommunicationGateway('chats', backend, requestorId),
    outgoingCustomChannelHttp: new StandardGateway(
      backend,
      `v1/orgs/:orgId/customers/:customerId/conversations/:conversationId/outgoing/custom-channel/messages`
    ),
    outgoingFbMessage: createOutgoingCommunicationGateway('facebook-message', backend, requestorId),
    outgoingNote: createOutgoingCommunicationGateway('notes', backend, requestorId),
    outgoingSms: createOutgoingCommunicationGateway('sms', backend, requestorId),
    outgoingEmailHttp: new StandardGateway(backend, `v1/orgs/:orgId/agents/:agentId/outgoing/emails`),
    outgoingChatHttp: new OutgoingChatGateway(backend),
    phoneControlsHttp: new PhoneControlsHttpGateway(backend, requestorId),
    phoneGateway: new TwilioPhoneGateway(backend, requestorId, twilio),
    phraseSuggestions: new PhraseSuggestionsGateway(backend, requestorId),
    publicAnswerUpload: new PublicAnswerUploadGateway(backend, requestorId),
    reactions: new StandardGateway(backend, `v1/orgs/:orgId/items/:itemId/reactions`),
    relationship: new NoopOnReconnectGateway(
      backend,
      `v1/orgs/:orgId/customers/:customerId/relationships/:relationshipId`
    ),
    relationshipLabels: new StandardGateway(backend, 'v1/orgs/:orgId/relationship-labels'),
    report: new ReportGateway(backend, requestorId),
    reportBuilderConfig: new StandardGateway(backend, 'v1/orgs/:orgId/configuration/report-builder'),
    reportConfigs: new StandardGateway(backend, `v1/report-configs`),
    reportingApi: new ReportingApiGateway(backend),
    routableInboxCounts: new RoutableInboxCountsGateway(backend),
    routingEvent: new RoutingEventGateway(backend, requestorId),
    routingGroup: new RoutingGroupGateway(backend, requestorId),
    routingQueueItem: new RoutingQueueItemGateway(backend, requestorId),
    scheduledReport: new ScheduledReportGateway(backend, requestorId),
    sharedReportConfigs: new StandardGateway(backend, 'reporting/v1/orgs/:orgId/shared-report-configs'),
    sharedReport: new StandardGateway(backend, 'reporting/v1/orgs/:orgId/shared-reports'),
    search: new SearchGateway(backend, requestorId),
    serverInfo: new ServerInfoGateway(backend),
    snippet: new SnippetGateway(backend),
    snippetCount: new SnippetCountGateway(backend, requestorId),
    snippetRevision: new StandardGateway(backend, 'v1/orgs/:orgId/snippets/:snippetId/revisions'),
    snippetSearchResultHttp: new SnippetSearchResultHttpGateway(backend, requestorId),
    snippetUpload: new SharedUploadGateway(backend, requestorId),
    stationConfiguration: new StationConfigurationGateway(backend, requestorId),
    suggestedReplies: new StandardGateway(backend, 'v1/orgs/:orgId/ai-suggested-replies'),
    diagnosticsConfiguration: new NoopOnReconnectGateway(backend, 'v1/orgs/:orgId/configuration/diagnostics'),
    systemConfiguration: new SystemConfigurationGateway(backend, requestorId),
    task: createOutgoingCommunicationGateway('tasks', backend, requestorId),
    taskComments: new NoopOnReconnectGateway(backend, 'v1/orgs/:orgId/tasks/:taskId/comments/:commentId'),
    taskFollowers: new NoopOnReconnectGateway(backend, 'v1/orgs/:orgId/tasks/:taskId/followers/:followerId'),
    team: new TeamGateway(backend, requestorId),
    topic: new TopicGateway(backend, requestorId),
    topicHierarchy: new StandardGateway(backend, 'v1/orgs/:orgId/topic-hierarchy/:topicHierarchyId'),
    topicSuggestions: new TopicSuggestionsGateway(backend, requestorId),
    threadsConfiguration: new StandardGateway(backend, 'v1/orgs/:orgId/configuration/threads'),
    tweet: new TweetGateway(commSimulation),
    user: new UserGateway(backend, requestorId),
    userPassword: new UserPasswordGateway(backend, requestorId),
    usersUpload: new UsersUploadGateway(backend, requestorId),
    vgsAccount: new StandardGateway(backend, 'v1/orgs/:orgId/configuration/public-accounts/vgs'),
    voiceConfiguration: createConfigurationGateway('voice', backend, requestorId),
    voiceRecording: new VoiceRecordingGateway(backend, requestorId),
    webhook: new WebhookGateway(backend),
    widgets: new StandardGateway(backend, 'v1/orgs/:orgId/widgets'),
    widgetActivity: new StandardGateway(backend, 'v1/orgs/:orgId/outgoing/widget-activity'),
  };
}
