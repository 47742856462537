import StandardGateway from './lib/standard_gateway';

// Override the default request timeout because tokens generation is
// done by Looker, which occasionally takes a while to respond.
const REQUEST_TIMEOUT = 60000;

export default class EmbedTokensGateway extends StandardGateway {
  constructor(backend) {
    super(backend, 'reporting/v1/orgs/:orgId/embed-tokens');
  }

  add(params, data) {
    return super.add(params, data, { timeout: REQUEST_TIMEOUT });
  }
}
